/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Heading, Text, FlexRow, FlexCol } from 'sarsaparilla';

const propTypes = {
    canSkip: PropTypes.bool,
    isLoading: PropTypes.bool,
    onOptOut: PropTypes.func,
    nextStep: PropTypes.func,
    startOver: PropTypes.func,
};

type MfaEnrollmentIntroductionProps = {
    canSkip: boolean;
    hideStartOverButton?: boolean;
    isLoading: boolean;
    onOptOut: () => void;
    nextStep: () => void;
    startOver: () => void;
};

function MfaEnrollmentIntroduction({
    canSkip,
    hideStartOverButton,
    isLoading,
    onOptOut,
    nextStep,
    startOver,
}: MfaEnrollmentIntroductionProps) {
    return (
        <FlexRow>
            <FlexCol xs={12}>
                <Heading
                    headingLevel={4}
                    appearance="h4"
                    headingAlign="center"
                    className="mb-3"
                >
                    Set Up Multi-Factor Authentication
                </Heading>

                <Text size="md" className="force-align-left mb-3">
                    Multi-factor authentication will be available for use starting March
                    1st, 2025 to protect your {process.env.SITE_NAME} account with an
                    extra layer of security, based on federal mandate.
                </Text>

                <Text size="md" className="mb-3 force-align-left">
                    <b>Step 1.</b> To get started, download and install your
                    agency-mandated authentication application for your phone, tablet, or
                    desktop. When you have completed the download, click Next to proceed
                    to the remaining steps.
                </Text>
            </FlexCol>

            {canSkip && (
                <FlexCol xs={12} className="mb-3">
                    <Button
                        appearance="link"
                        onClick={onOptOut}
                        isDisabled={isLoading}
                        gaTrackingId="261677614215"
                    >
                        Skip for now
                    </Button>
                </FlexCol>
            )}
            <FlexCol xs={12} className="mb-3">
                <Button
                    onClick={nextStep}
                    isLoading={isLoading}
                    gaTrackingId="261677614215"
                >
                    Next
                </Button>
            </FlexCol>
            {!hideStartOverButton && (
                <FlexCol xs={12}>
                    <Text size="md" className="start-over-wrap">
                        <Button
                            href="/internal/account/login"
                            appearance="link"
                            className="start-over-btn"
                            onClick={startOver}
                            rel="noreferrer"
                            gaTrackingId="261677614215"
                        >
                            Start over
                        </Button>{' '}
                        and return to login page
                    </Text>
                </FlexCol>
            )}
        </FlexRow>
    );
}

MfaEnrollmentIntroduction.propTypes = propTypes;

export default MfaEnrollmentIntroduction;

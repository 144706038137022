/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import {
    Button,
    ButtonGroup,
    ExternalLink,
    FlexCol,
    FlexRow,
    Text,
    useFlags,
} from 'sarsaparilla';

type LoginMethodsProps = {
    handleHubButton: () => void;
    handleOidcButton: () => void;
};

export default function LoginMethods({
    handleHubButton,
    handleOidcButton,
}: LoginMethodsProps) {
    const { iaEnableMfa } = useFlags();

    return (
        <div className="login-btn-wrap">
            <FlexRow>
                <FlexCol>
                    <ButtonGroup isStretchedToFit>
                        <Button
                            onClick={handleHubButton}
                            appearance="tertiary"
                            type="button"
                            id="hub-method"
                            gaTrackingId="819335029303"
                            className="mb-2"
                        >
                            Use Hub Login
                        </Button>
                    </ButtonGroup>
                </FlexCol>
                <FlexCol>
                    <ButtonGroup isStretchedToFit>
                        <Button
                            onClick={handleOidcButton}
                            appearance="tertiary"
                            type="button"
                            id="oidc-method"
                            gaTrackingId="819335029303"
                            className="mb-2"
                        >
                            Use Login.gov
                        </Button>
                    </ButtonGroup>
                </FlexCol>
            </FlexRow>
            <FlexRow>
                <FlexCol className="mb-3">
                    <Text className="left">
                        Use your Hub account email
                        {iaEnableMfa
                            ? ', password, and an MFA TOTP device (e.g. Google authentication, MS authenticator, Duo)'
                            : ' and password'}{' '}
                        to sign in.
                    </Text>
                </FlexCol>
                <FlexCol className="mb-3">
                    <Text className="left">
                        Use the same username and password to log into all participating
                        .gov websites. You will need your{' '}
                        <ExternalLink url="https://login.gov" gaTrackingId="819335029303">
                            Login.gov
                        </ExternalLink>{' '}
                        account email, password, and a secondary authentication method;
                        PIV, CAC, LincPass, or secure key device to sign in.
                    </Text>
                </FlexCol>
            </FlexRow>
        </div>
    );
}

/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Button, FlexCol, FlexRow, Heading, Spacer, Text } from 'sarsaparilla';

const propTypes = {
    isLoading: PropTypes.bool,
    codes: PropTypes.string,
    onCodeChange: PropTypes.func,
    nextStep: PropTypes.func,
};

type MfaEnrollmentVerificationProps = {
    isLoading: boolean;
    codes: string;
    onCodeChange: (value: string) => void;
    nextStep: () => void;
    startOver: () => void;
};

function MfaEnrollmentVerification({
    isLoading,
    codes,
    onCodeChange,
    nextStep,
    startOver,
}: MfaEnrollmentVerificationProps) {
    const isValidCode = (code: string): boolean => {
        const regex = /[0-9]{6}/g;
        return !isEmpty(code) && regex.test(code);
    };

    const handleCodeChange = (e: { target: { validity: any; value: string } }) => {
        const code = e.target.value;
        if (e.target.validity.valid && code.length < 7) onCodeChange(code);
    };

    return (
        <>
            <p className="mt-3 ml-8 mb-4 mr-8">
                <Heading headingLevel={4} appearance="h4">
                    Set Up Multi-Factor Authentication
                </Heading>
            </p>
            <p className="ml-3 mr-3 mb-3">
                <Text size="md">
                    <b>Step 3.</b> Enter the 6-digit verification code you see on your
                    agency-mandated multi-factor authentication application.
                </Text>
                <FlexRow className="mt-2">
                    <FlexCol>
                        <input
                            id="codes"
                            type="text"
                            className="mfa-input-codes"
                            pattern="[0-9]*"
                            value={codes}
                            onChange={handleCodeChange}
                        />
                    </FlexCol>
                </FlexRow>
            </p>
            <p>
                <Button
                    onClick={nextStep}
                    isDisabled={!isValidCode(codes) || isLoading}
                    gaTrackingId="463350020648"
                >
                    Continue
                </Button>
                <Spacer size="md" />
                <Button
                    href="/internal/account/login"
                    appearance="link"
                    className="start-over-btn"
                    onClick={startOver}
                    rel="noreferrer"
                    gaTrackingId="261677614215"
                >
                    Start over
                </Button>{' '}
                and return to login page
                <Text size="md" className="mt-2">
                    Complete process by clicking the above button. <b>Note</b>, you will
                    be taken to another window to retrieve Backup Codes in the event you
                    cannot access your agency-mandated multi-factor authentication
                    application.
                </Text>
            </p>
        </>
    );
}

MfaEnrollmentVerification.propTypes = propTypes;

export default MfaEnrollmentVerification;

/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import { Switch, useHistory, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'sarsaparilla';
import { checkLoggedIn } from 'ui-internal-account';
import InternalErrorPage from '../src/containers/InternalErrorPage.jsx';
import { locationChange } from '../src/actions/routerLocation';
import makeSwitchRoutes from './makeSwitchRoutes';

export default function App() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        dispatch(locationChange(location));
    }, [dispatch, location]);

    useEffect(() => {
        dispatch(checkLoggedIn(window.location, history));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="appWrapper">
            <ErrorBoundary
                hasDefaultWrapperClass={false}
                fallbackElement={<InternalErrorPage />}
            >
                <Switch>{makeSwitchRoutes()}</Switch>
            </ErrorBoundary>
        </div>
    );
}

if (process.env.REACT_AXE_ENV === 'local') {
    // eslint-disable-next-line
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 5000);
}

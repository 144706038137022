/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FlexCol, FlexRow, Spacer, useFlags } from 'sarsaparilla';

const propTypes = {
    user: PropTypes.shape({
        should_receive_sms: PropTypes.bool,
        locked: PropTypes.bool,
        is_email_confirmed: PropTypes.bool,
        user_id: PropTypes.string,
        last_logged_in_at: PropTypes.string,
        must_set_password: PropTypes.bool,
        password_set_at: PropTypes.string,
        last_attested_at: PropTypes.string,
        created_at: PropTypes.string,
        enforce_effective_dates: PropTypes.bool,
        effective_start_at: PropTypes.string,
        effective_end_at: PropTypes.string,
    }),
};

const formatTimes = (value) => {
    // TODO: replace moment here
    const dateFormat = 'MMM Do, YYYY';
    let valueInTime = 'never';
    let valueInTimeAgo = '';
    if (value) {
        const m = moment(value);
        valueInTime = m.format(dateFormat);
        valueInTimeAgo = `(${m.fromNow()})`;
    }
    return { valueInTime, valueInTimeAgo };
};

function LabelValueRow({ label, value }) {
    return (
        <FlexRow className={'mt-1'}>
            <FlexCol sm={3}>
                <b>{label}:</b>
            </FlexCol>
            <FlexCol sm={5}>{value}</FlexCol>
        </FlexRow>
    );
}
LabelValueRow.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
};

function LabelRow({ children }) {
    return (
        <FlexRow className={'mt-1'}>
            <FlexCol sm={8}>
                <b>{children}</b>
            </FlexCol>
        </FlexRow>
    );
}
LabelRow.propTypes = {
    children: PropTypes.any,
};

function LabelAndTimestampAgo({ label, timestamp }) {
    const { valueInTime, valueInTimeAgo } = formatTimes(timestamp);
    return <LabelValueRow label={label} value={`${valueInTime} ${valueInTimeAgo}`} />;
}
LabelAndTimestampAgo.propTypes = {
    label: PropTypes.string,
    timestamp: PropTypes.string,
};

export default function AdditionalInformation({ user }) {
    const { enableIaEffectiveDates } = useFlags();

    if (!user) {
        return null;
    }

    const showEffectiveDates = enableIaEffectiveDates && user.enforce_effective_dates;
    // TODO: replace moment here
    const accountInactive = moment().isBefore(user?.effective_start_at);
    const accountExpired = moment().isAfter(user?.effective_end_at);

    return (
        <div className="update-user-options">
            <h6>History</h6>
            <LabelAndTimestampAgo label={'Created at'} timestamp={user.created_at} />
            <LabelAndTimestampAgo
                label={'Last logged in'}
                timestamp={user.last_logged_in_at}
            />
            <LabelAndTimestampAgo
                label={'Password last set'}
                timestamp={user.password_set_at}
            />
            <LabelAndTimestampAgo
                label={'Last attested to'}
                timestamp={user.last_attested_at}
            />
            <Spacer size="md" />
            {user.must_set_password && (
                <LabelRow>User&apos;s password has expired</LabelRow>
            )}
            {!user.is_email_confirmed && (
                <LabelRow>User&apos;s email has not been confirmed</LabelRow>
            )}

            {showEffectiveDates && accountInactive && (
                <LabelRow>
                    Account not active until{' '}
                    {moment(user?.effective_start_at).format('MM/DD/YYYY')}
                </LabelRow>
            )}

            {showEffectiveDates && accountExpired && (
                <LabelRow>
                    Account expired on{' '}
                    {moment(user?.effective_end_at).format('MM/DD/YYYY')}
                </LabelRow>
            )}

            {user.should_receive_sms && <LabelRow>Opted-in to text messages</LabelRow>}
        </div>
    );
}

AdditionalInformation.propTypes = propTypes;

/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */
import React, { useState, useEffect } from 'react';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { TextBlock, Alert, StyledModal, getSanitizedRedirectUrl } from 'sarsaparilla';
import download from 'downloadjs';
import { logOut, navigateLogin } from '../actions/login';
import {
    assignMfaOptOut,
    generateMfaTotp,
    clearGenerateMfaTotp,
    validateMfaFactor,
    createMfaBackupCodes,
} from '../actions/mfa';
import {
    errorForMfaOptOut,
    errorForMfaTotp,
    errorForMfaFactor,
    errorForMfaBackupCodes,
} from '../utilities/errorMessages';
import MfaEnrollmentIntroduction from './mfaEnrollment/MfaEnrollmentIntroduction';
import MfaEnrollmentSetupScan from './mfaEnrollment/MfaEnrollmentSetupScan';
import MfaEnrollmentVerification from './mfaEnrollment/MfaEnrollmentVerification';
import MfaEnrollmentBackupCodes from './mfaEnrollment/MfaEnrollmentBackupCodes';

type MfaState = {
    manualKey: string;
    qrCode: string;
    backupCodes: Array<string>;
    generateMfaTotpError: boolean;
    patchMfaOptOutError: boolean;
    validateMfaFactorError: boolean;
    createMfaBackupCodesError: boolean;
    error: string;
    isLoading: boolean;
};

type MfaEnrollmentWizardModalProps = MfaState & {
    canSkip: boolean;
    hideStartOverButton?: boolean;
    isOpen?: boolean;
    isEmbedded?: boolean;
    onClose?: () => void;
    generateTotp: () => void;
    clearTotp: () => void;
    assignOptOut: (
        nextPathname: string | undefined,
        history: RouteComponentProps['history']
    ) => void;
    validateFactor: (token: string, factor: number) => void;
    createBackupCodes: () => void;
    startOverLogin: () => void;
};

type MfaEnrollmentWizardModalState = {
    mfa: MfaState;
    loading: {
        display: boolean;
    };
};

export function MfaEnrollmentWizardModal({
    canSkip,
    hideStartOverButton,
    isOpen,
    isEmbedded,
    onClose,
    manualKey,
    qrCode,
    backupCodes,
    generateMfaTotpError,
    patchMfaOptOutError,
    validateMfaFactorError,
    createMfaBackupCodesError,
    error,
    isLoading,
    generateTotp,
    clearTotp,
    assignOptOut,
    validateFactor,
    createBackupCodes,
    startOverLogin,
}: MfaEnrollmentWizardModalProps) {
    const history = useHistory();
    const [step, setStep] = useState<number>(0);
    const [codes, setCodes] = useState<string>('');
    const [generated, setGenerated] = useState<boolean>(false);
    const [validated, setValidated] = useState<boolean>(false);
    const [created, setCreated] = useState<boolean>(false);

    const handleCodeChange = (code: string) => {
        setCodes(code);
    };

    const handleClose = () => {
        setStep(0);
        if (onClose) onClose();
    };

    const optOut = () => {
        const redirect = getSanitizedRedirectUrl();
        assignOptOut(redirect, history);
        handleClose();
    };

    const generate = () => {
        generateTotp();
        setGenerated(true);
    };

    const validate = () => {
        validateFactor(codes, 0);
        setValidated(true);
    };

    const create = () => {
        createBackupCodes();
        setCreated(true);
    };

    const complete = () => {
        clearTotp();
        if (isOpen) {
            handleClose();
        }
        if (isEmbedded) {
            navigateLogin('/internal/account/hub');
        }
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const startOver = () => {
        startOverLogin();
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <MfaEnrollmentSetupScan
                        hideStartOverButton={hideStartOverButton}
                        mfaManualEntry={manualKey}
                        qrCode={qrCode}
                        nextStep={nextStep}
                        startOver={startOver}
                    />
                );
            case 2:
                return (
                    <MfaEnrollmentVerification
                        isLoading={isLoading}
                        codes={codes}
                        onCodeChange={handleCodeChange}
                        nextStep={validate}
                        startOver={startOver}
                    />
                );
            case 3:
                return (
                    <MfaEnrollmentBackupCodes onDownload={create} onClose={complete} />
                );
            default:
                return (
                    <MfaEnrollmentIntroduction
                        canSkip={canSkip}
                        hideStartOverButton={hideStartOverButton}
                        isLoading={isLoading}
                        onOptOut={optOut}
                        nextStep={generate}
                        startOver={startOver}
                    />
                );
        }
    };

    const renderContainer = () => {
        return (
            <>
                {error && generateMfaTotpError && (
                    <Alert shouldFocusOnMount type="error" className="mb-2">
                        {errorForMfaTotp()}
                    </Alert>
                )}
                {error && patchMfaOptOutError && (
                    <Alert shouldFocusOnMount type="error" className="mb-2">
                        {errorForMfaOptOut()}
                    </Alert>
                )}
                {error && validateMfaFactorError && (
                    <Alert shouldFocusOnMount type="error" className="mb-2">
                        {errorForMfaFactor()}
                    </Alert>
                )}
                {error && createMfaBackupCodesError && (
                    <Alert shouldFocusOnMount type="error" className="mb-2">
                        {errorForMfaBackupCodes()}
                    </Alert>
                )}
                <TextBlock width="xl" alignX="center" textAlign="center">
                    {renderStep()}
                </TextBlock>
            </>
        );
    };

    useEffect(() => {
        clearTotp();
    }, [clearTotp]);

    useEffect(() => {
        if (generated && generateMfaTotpError) {
            setStep(0);
            setGenerated(false);
            setTimeout(() => {
                clearTotp();
            }, 10000);
        }
        if (generated && !generateMfaTotpError) {
            setStep(1);
        }
    }, [generated, generateMfaTotpError, clearTotp]);

    useEffect(() => {
        if (validated && validateMfaFactorError) {
            setStep(2);
            setValidated(false);
            setTimeout(() => {
                clearTotp();
            }, 10000);
        }
        if (validated && !validateMfaFactorError) {
            setStep(3);
        }
    }, [validated, validateMfaFactorError, clearTotp]);

    useEffect(() => {
        if (created && createMfaBackupCodesError) {
            setStep(3);
            setCreated(false);
            setTimeout(() => {
                clearTotp();
            }, 10000);
        }
        if (created && !createMfaBackupCodesError) {
            setCreated(false);
            download(backupCodes.join('\n'), 'mfa_backup_codes.txt', 'text/plain');
        }
    }, [created, createMfaBackupCodesError, backupCodes, clearTotp]);

    if (isEmbedded) {
        return renderContainer();
    }

    return (
        <StyledModal
            size="sm"
            isOpen={isOpen ?? false}
            onRequestClose={handleClose}
            shouldShowCloseButton={false}
        >
            {renderContainer()}
        </StyledModal>
    );
}

const mapStateToProps = (state: MfaEnrollmentWizardModalState) => {
    return {
        manualKey: state.mfa?.manualKey,
        qrCode: state.mfa?.qrCode,
        backupCodes: state.mfa?.backupCodes,
        generateMfaTotpError: state.mfa?.generateMfaTotpError,
        patchMfaOptOutError: state.mfa?.patchMfaOptOutError,
        validateMfaFactorError: state.mfa?.validateMfaFactorError,
        createMfaBackupCodesError: state.mfa?.createMfaBackupCodesError,
        error: state.mfa?.error,
        isLoading: state.loading?.display,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        generateTotp: () => dispatch(generateMfaTotp()),
        clearTotp: () => dispatch(clearGenerateMfaTotp()),
        assignOptOut: (
            nextPathname: string | undefined,
            history: RouteComponentProps['history']
        ) => dispatch(assignMfaOptOut(nextPathname, history)),
        validateFactor: (token: string, factor: number) =>
            dispatch(validateMfaFactor(token, factor)),
        createBackupCodes: () => dispatch(createMfaBackupCodes()),
        startOverLogin: () => dispatch(logOut(window.location.pathname)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MfaEnrollmentWizardModal);

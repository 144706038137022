/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    HelmetWrapperInternal,
    EmailField,
    emailValidator,
    Alert,
    Button,
    Heading,
    Spacer,
} from 'sarsaparilla';

import { recoverPassword } from '../actions/recoverPassword';
import { errorForPasswordRecovery } from '../utilities/errorMessages';

const propTypes = {
    resetPassword: PropTypes.shape({
        email: PropTypes.string,
        error: PropTypes.any,
        success: PropTypes.any,
    }),
    recover: PropTypes.func,
};

export class RecoverPasswordPage extends React.Component {
    static propTypes = propTypes;

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            emailValidationError: false,
        };
    }

    componentDidMount() {
        if (this.props.resetPassword && this.props.resetPassword.email) {
            this.setState({ email: this.props.resetPassword.email });
        }
    }

    recover = (e) => {
        if (!emailValidator(this.state.email).isValid) {
            this.setState({
                emailValidationError: true,
            });

            return;
        }

        e.preventDefault();

        this.setState({
            emailValidationError: false,
        });

        this.props.recover(this.state.email);
    };

    handleEmailChange = (e) => {
        this.setState({ email: e.target.value });
    };

    response() {
        if (this.props.resetPassword && this.props.resetPassword.error) {
            return (
                <Alert shouldFocusOnMount type="error">
                    {errorForPasswordRecovery()}
                </Alert>
            );
        }
        if (this.props.resetPassword && this.props.resetPassword.success) {
            return (
                <Alert shouldFocusOnMount type="success">
                    <p>
                        An email has been sent to your inbox. Please allow up to 60
                        seconds for the email to appear and follow the instructions
                        provided within.
                    </p>
                </Alert>
            );
        }
        return null;
    }

    render() {
        return (
            <div className="logged-out-container">
                <HelmetWrapperInternal title="Forgot Password" />
                <form onSubmit={this.recover}>
                    <Heading headingLevel={1} appearance="h1">
                        Forgot Password
                    </Heading>
                    <div>
                        <p>
                            Enter your email address and we will send you an email with a
                            link to reset your password.
                        </p>

                        {this.state.emailValidationError && (
                            <Alert type="error" className="mb-2">
                                That is not a valid email address.
                            </Alert>
                        )}

                        <EmailField
                            id="forgot-password-email"
                            onChange={this.handleEmailChange}
                            value={this.state.email}
                            isRequired
                            aria-label="Email"
                            placeholder="Email"
                        />
                    </div>

                    {this.response()}
                    <Spacer size="md" />
                    <Button
                        type="submit"
                        disabled={!this.state.email}
                        id="recover"
                        onClick={this.recover}
                        shouldFitContainer
                        className="mb-3"
                        gaTrackingId="694744717423"
                    >
                        Reset
                    </Button>

                    <Button
                        appearance="link"
                        id="back-to-login"
                        to="/internal/account/login"
                        gaTrackingId="694744717423"
                    >
                        Back to Login
                    </Button>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        resetPassword: state.resetPassword,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        recover: (email) => dispatch(recoverPassword(email)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPasswordPage);

/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import {
    Alert,
    Button,
    ButtonGroup,
    EmailField,
    emailValidator,
    HelmetWrapperInternal,
    Spacer,
    StyledModal,
    Text,
    TextField,
    useFlags,
} from 'sarsaparilla';
import { loginMessagesStorageKey } from 'ui-internal-account/src/actions/login';
import {
    AvailableOidcProviders,
    oidcRedirect,
} from 'ui-internal-account/src/actions/oidc';
import useOidcConfig from 'ui-internal-account/src/hooks/useOidcConfig';
import { errorForLogin } from 'ui-internal-account/src/utilities/errorMessages';
import LoginMethods from './loginMethods';

function LoginFormHeader({
    title,
    info,
    showLoginForm,
}: {
    title: string;
    info: string;
    showLoginForm: boolean;
}) {
    return (
        <>
            <h1>{title}</h1>
            {!showLoginForm && (
                <Text fontWeight="bold">
                    Select the login method recommended by your Agency to access the{' '}
                    <a href="/internal/account/hub">{process.env.SITE_NAME} Hub.</a>
                </Text>
            )}
            {info && (
                <Alert type="info" className="my-3">
                    {info}
                </Alert>
            )}
        </>
    );
}

type LoginFormProps = {
    loginFn: (email: string, password: string) => void;
    initialEmail: string;
    setEmailFn: (email: string) => void;
    errorMessage: string;
    attempts: number;
    showConfirmEmailChange: boolean | null;
};

type ErrorMessageProps = {
    errorMessage: string;
    concurrentSessionError: boolean;
    attempts: number;
};

function ErrorMessage({
    errorMessage,
    concurrentSessionError,
    attempts,
}: ErrorMessageProps) {
    if (errorMessage || concurrentSessionError) {
        let err = errorForLogin(errorMessage, attempts);

        if (concurrentSessionError) {
            err =
                'You have been logged out of your current session because this account is logged in with another browser. ' +
                'Please sign in again to gain access to your account and force a logout of the other session.';
        }

        return (
            <Alert shouldFocusOnMount type="error">
                {err}
            </Alert>
        );
    }

    return null;
}

export function LoginForm({
    loginFn,
    initialEmail,
    errorMessage,
    setEmailFn,
    attempts,
    showConfirmEmailChange,
}: LoginFormProps) {
    const { enableOidc } = useFlags();

    const [email, setEmailField] = React.useState(initialEmail);
    const [showTroubleLoggingIn, setShowTroubleLoggingIn] = React.useState(false);
    const [emailIsValid, setEmailIsValid] = React.useState(true);
    const [password, setPassword] = React.useState('');
    const [loginMessageFlags, setLoginMessageFlags] = React.useState({
        concurrentSessionError: false,
        loggedOutForInactivity: false,
    });
    const [showLoginForm, setShowLoginForm] = React.useState(false);
    const oidcCfg = useOidcConfig();

    React.useEffect(() => {
        if (enableOidc !== null && enableOidc !== undefined) {
            setShowLoginForm(!enableOidc);
        }

        const loginMessagesString = localStorage.getItem(loginMessagesStorageKey);
        if (loginMessagesString) {
            setLoginMessageFlags(JSON.parse(loginMessagesString));
        }
    }, [enableOidc]);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailField(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const onLogin = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!emailValidator(email).isValid) {
            setEmailIsValid(false);
            return;
        }

        setEmailIsValid(true);
        loginFn(email, password);
    };

    const setEmail = () => {
        setEmailFn(email);
    };

    const handleHubButton = () => setShowLoginForm(true);
    const handleOidcButton = () => {
        setShowLoginForm(false);
        oidcRedirect(oidcCfg, AvailableOidcProviders.loginDotGov);
    };

    const renderLoginMethods = () => {
        return (
            <LoginMethods
                handleHubButton={handleHubButton}
                handleOidcButton={handleOidcButton}
            />
        );
    };

    const renderLoginForm = () => {
        return (
            <div className="login-form-container">
                {!emailIsValid && (
                    <Alert type="error" className="mb-2">
                        That is not a valid email address.
                    </Alert>
                )}
                <div>
                    <EmailField
                        id="email"
                        onChange={handleEmailChange}
                        value={email}
                        aria-label="Email Address"
                        placeholder="Email Address"
                    />
                </div>
                <div>
                    <TextField
                        label=""
                        type="password"
                        id="password"
                        onChange={handlePasswordChange}
                        value={password}
                        aria-label="Password"
                        placeholder="Password"
                        enableShowPassword
                    />
                </div>
                <ErrorMessage
                    attempts={attempts}
                    errorMessage={errorMessage}
                    concurrentSessionError={loginMessageFlags?.concurrentSessionError}
                />
                <Spacer size="md" />
                <ButtonGroup isStacked>
                    <Button
                        type="submit"
                        id="login"
                        gaTrackingId="819335029303"
                        className="mb-3"
                    >
                        Login
                    </Button>
                    {enableOidc && (
                        <Button
                            onClick={() => {
                                setShowLoginForm(false);
                            }}
                            appearance="tertiary"
                            type="button"
                            id="login-methods"
                            gaTrackingId="819335029303"
                            className="mt-0 mb-3"
                        >
                            Return to Sign In Options
                        </Button>
                    )}
                </ButtonGroup>
                <div>
                    <Button
                        appearance="link"
                        id="forgot-password"
                        to="/internal/account/recover-password"
                        onClick={setEmail}
                        gaTrackingId="819335029303"
                    >
                        Forgot Password?
                    </Button>
                </div>
                <div>
                    <Button
                        appearance="link"
                        onClick={() => setShowTroubleLoggingIn(true)}
                    >
                        Trouble Logging In?
                    </Button>
                </div>
                <StyledModal
                    isOpen={showTroubleLoggingIn}
                    heading="Trouble Logging In?"
                    onRequestClose={() => setShowTroubleLoggingIn(false)}
                >
                    Below is a list of possible issues that might be preventing you from
                    logging in.
                    <ul>
                        <li>Account is locked.</li>
                        <li>Account is outside of effective dates.</li>
                        <li>Did not correctly enter current password</li>
                        <li>Did not correctly enter email address for account.</li>
                        <li>Did not enter MFA code correctly.</li>
                        <li>MFA code expired before entry.</li>
                        <li>Used an invalid MFA backup code.</li>
                    </ul>
                    For assistance, contact your manager, or the help desk at (877) -
                    345-6777
                </StyledModal>
            </div>
        );
    };

    let title = 'Sign In';
    let info = '';
    if (loginMessageFlags.loggedOutForInactivity) {
        title = 'Session Timeout';
        info =
            'You have been logged out automatically due to inactivity. Login with your email and password.';
    }
    if (showConfirmEmailChange === true) {
        title = 'Confirm Email Change';
    }

    const renderContent = () => {
        if (showLoginForm) {
            return renderLoginForm();
        }
        return renderLoginMethods();
    };

    return (
        <form onSubmit={onLogin}>
            <div className="login-container">
                <HelmetWrapperInternal title={title} />
                <LoginFormHeader
                    title={title}
                    info={info}
                    showLoginForm={showLoginForm}
                />
                {renderContent()}
            </div>
        </form>
    );
}

/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { isEmpty, isEqual, isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Alert,
    Button,
    ButtonGroup,
    Checkbox,
    EmailField,
    FeatureFlag,
    FlexCol,
    FlexRow,
    Heading,
    Icons,
    ModalActions,
    phoneValidator,
    showToastAction,
    Spacer,
    StyledModal,
    Switch,
    TextArea,
    TextFieldStateless,
    TYPES,
} from 'sarsaparilla';
import { enablePasswordReset } from '../../actions/resetPassword';
import { cancelEmailChange, cancelEmailChangeReset } from '../../actions/confirmEmail';
import {
    cancelUpdate,
    continueFromCreatingUser,
    deleteRole,
    editingUser,
    editUserRoles,
    emailChangeCancelled,
    resendConfirmation,
    resetConfirmStatus,
    resetMFA,
    resetResetStatus,
    setDefaultRole,
    setMFABypass,
    updateConcAssignment,
    updateUser,
    userMFAEnrollmentStatus,
} from '../../actions/updateUser';
import { addNoteForExistingUser } from '../../actions/notes';
import { postMfaBackupCodes } from '../../actions/mfa';
import ActionErrorDisplay from '../ActionErrorDisplay';
import EditUserProgress from '../EditUserProgress';
import AdditionalInformation from './AdditionalInformation';
import PhoneNumbers from './PhoneNumbers';
import ResendConfirmEmail from './ResendConfirmEmail';
import ResetPassword from './ResetPassword';
import RolesTable from './RolesTable';
import EffectiveDates from '../UserEffectiveDates';
import { errorForUpdateUser } from '../../utilities/errorMessages';
import {
    ATR,
    BAH_ADMIN,
    CSR1,
    CSR2,
    CSR_SUPERVISOR,
    doesUserHaveRoleType,
    PMO,
    SUPER_USER,
} from '../../utilities/roles';
import UserNotes from '../UserNotes';

export class UpdateUser extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func,
        emailChangeCancelled: PropTypes.func,
        setMFABypassFn: PropTypes.func,
        createToast: PropTypes.func,
        reloadAccount: PropTypes.bool,
        match: TYPES.MATCH,
        history: TYPES.HISTORY,
        location: TYPES.LOCATION,
        accountToSave: PropTypes.object,
        profile: PropTypes.any,
        creating: PropTypes.bool,
        readOnly: PropTypes.bool,
        requestConfirmResponse: PropTypes.object,
        errorEnablingPasswordReset: PropTypes.any,
        userIdEnabledPasswordReset: PropTypes.any,
        error: PropTypes.any,
        savedAccount: PropTypes.object,
        continueFromCreate: PropTypes.func,
        successForCancel: PropTypes.bool,
        setDefaultRole: PropTypes.func,
        save: PropTypes.func,
        cancelEmailChange: PropTypes.func,
        resendConfirmation: PropTypes.func,
        resetPassword: PropTypes.func,
        editUserRoles: PropTypes.func,
        updateConcAssignment: PropTypes.func,
        cancelUpdate: PropTypes.func,
        deleteRole: PropTypes.func,
        loggedInUser: PropTypes.shape({
            can_run_transactions: PropTypes.bool,
            created_at: PropTypes.string,
            email: PropTypes.string,
            first_name: PropTypes.string,
            is_concessionaire: PropTypes.bool,
            is_email_confirmed: PropTypes.bool,
            last_logged_in_at: PropTypes.string,
            last_name: PropTypes.string,
            locked: PropTypes.bool,
            must_set_password: PropTypes.bool,
            password: PropTypes.string,
            password_set_at: PropTypes.string,
            phone_numbers: PropTypes.array,
            roles: PropTypes.array,
            should_receive_sms: PropTypes.bool,
            user_id: PropTypes.string,
            inherits_concessionaire_roles: PropTypes.bool,
            mfa_data: PropTypes.shape({
                bypass_at: PropTypes.string,
                opt_out_at: PropTypes.string,
                verified_at: PropTypes.string,
            }),
        }),
        postMfaBackupCodes: PropTypes.func,
        // OLD KILL //
        concessionaire: PropTypes.object,
        // OLD //
        concessionaires: PropTypes.array,
        resetMFA: PropTypes.func,
        addNoteForUpdate: PropTypes.func,
    };

    constructor(props) {
        super(props);

        const phoneNumbers = this.createPhoneNumberListFromUser();
        const isCsrOrPmoOrApmOrSuper =
            this.props.loggedInUser &&
            this.props.loggedInUser.roles.some((role) => {
                return [SUPER_USER, BAH_ADMIN, PMO, ATR, CSR1].includes(role.role_type);
            });
        const isCsrSupervisor = this.props.loggedInUser?.roles?.some((role) => {
            return [CSR_SUPERVISOR].includes(role.role_type);
        });
        const canControlMFA = this.props.loggedInUser?.roles?.some((role) =>
            [SUPER_USER, BAH_ADMIN, PMO, CSR2, CSR_SUPERVISOR].includes(role.role_type)
        );
        const canDoMFABypass = this.props.loggedInUser?.roles?.some((role) =>
            [SUPER_USER, BAH_ADMIN, PMO].includes(role.role_type)
        );
        const isConcessionaire = this.isUserConcessionaire(this.props.loggedInUser);
        const isConcessionaireManager = this.isUserConcessionaireManager(
            this.props.loggedInUser
        );
        const userMFAStatus = userMFAEnrollmentStatus(this.props.accountToSave);

        this.state = {
            phoneNumbers,
            canCreateConcessionaireManager: isCsrOrPmoOrApmOrSuper,
            canCreateConcessionaire: isConcessionaire,
            creatingConcessionaire: false,
            loggedInConcessionaire: isConcessionaire,
            loggedInConcessionaireManager: isConcessionaireManager,
            edited: this.props.creating,
            isCsrSupervisor,
            isValidPhoneNumber: true,
            canControlMFA,
            canDoMFABypass,
            isMFAModalOpen: false,
            isMFABypassModalOpen: false,
            isNewMFABackupCodesModalOpen: false,
            bypassMFAJustification: '',
            titleMFAModal: '',
            bodyMFAModal: '',
            actionMFAModal: () => {},
            userMFAStatus,
            user: {
                first_name: '',
                last_name: '',
                email: '',
                locked: false,
                can_run_transactions: false,
                should_receive_sms: false,
                enforce_effective_dates: false,
            },
            showAlert: false,
            changesCounter: 0,
            initialPhoneNumbers: phoneNumbers,
        };

        this.onPhoneExtensionChange = this.onPhoneExtensionChange.bind(this);
        this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this);
        this.onPhoneTypeChange = this.onPhoneTypeChange.bind(this);
        this.isEditingConcessionaireManager =
            this.isEditingConcessionaireManager.bind(this);
        this.updateStateUser = this.updateStateUser.bind(this);
        this.setEnableEffectiveDates = this.setEnableEffectiveDates.bind(this);
        this.cbEffectiveDates = this.cbEffectiveDates.bind(this);
        this.saveEnabled = this.saveEnabled.bind(this);
        this.isLoggedUserSameRoleAsUser = this.isLoggedUserSameRoleAsUser.bind(this);
        this.saveAllowMFABypass = this.saveAllowMFABypass.bind(this);
        this.save = this.save.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
    }

    componentDidMount() {
        const { dispatch, location, match } = this.props;

        this.setInitialValues(this.props.accountToSave);

        if (location && location.pathname.includes('/internal/account/edit-user')) {
            if (match && match.params.userId) {
                dispatch(editingUser(match.params.userId));
            }

            dispatch(resetResetStatus());
            dispatch(resetConfirmStatus());
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.reloadAccount &&
            this.props.accountToSave !== prevProps.accountToSave
        ) {
            const phoneNumbers = this.createPhoneNumberListFromUser(this.props);
            const creatingConcessionaire =
                this.props.accountToSave && this.props.accountToSave.is_concessionaire;
            const userMFAStatus = userMFAEnrollmentStatus(this.props.accountToSave);
            this.setState(
                {
                    phoneNumbers,
                    creatingConcessionaire,
                    userMFAStatus,
                },
                () => {
                    this.setInitialValues(this.props.accountToSave);
                }
            );
        }
    }

    onPhoneTypeChange(index, type) {
        this.setState((prevState) => {
            const updatedPhoneNumbers = [...prevState.phoneNumbers];

            updatedPhoneNumbers[index] = {
                phone: prevState.phoneNumbers[index].phone,
                ext: type === 'work' ? prevState.phoneNumbers[index].ext : '',
                type,
            };

            return {
                phoneNumbers: updatedPhoneNumbers,
                changesCounter: this.countDifferences(
                    prevState.phoneNumbers ?? prevState.initialPhoneNumbers,
                    updatedPhoneNumbers
                ),
            };
        });
    }

    onPhoneExtensionChange(index, event) {
        this.setState((prevState) => {
            const updatedPhoneNumbers = [...prevState.phoneNumbers];

            updatedPhoneNumbers[index] = {
                phone: prevState.phoneNumbers[index].phone,
                ext: event.target.value,
                type: prevState.phoneNumbers[index].type,
            };
            return {
                phoneNumbers: updatedPhoneNumbers,
                changesCounter: this.countDifferences(
                    prevState.phoneNumbers ?? prevState.initialPhoneNumbers,
                    updatedPhoneNumbers
                ),
            };
        });
    }

    onPhoneNumberChange(index, event) {
        this.setState((prevState) => {
            const updatedPhoneNumbers = [...prevState.phoneNumbers];

            updatedPhoneNumbers[index] = {
                phone: event.target.value,
                ext: prevState.phoneNumbers[index].ext,
                type: prevState.phoneNumbers[index].type,
            };

            return {
                phoneNumbers: updatedPhoneNumbers,
                changesCounter: this.countDifferences(
                    prevState.phoneNumbers ?? prevState.initialPhoneNumbers,
                    updatedPhoneNumbers
                ),
            };
        });
    }

    setInitialValues(user) {
        // TODO: is_concessionaries is now handled via state, good to do for all other fields... eventually
        if (user) {
            this.updateStateUser({
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                locked: user.locked,
                can_run_transactions: user.can_run_transactions,
                should_receive_sms: user.should_receive_sms,
                enforce_effective_dates:
                    this.props.accountToSave?.enforce_effective_dates,
                effective_start_at: this.props.accountToSave?.effective_start_at,
                effective_end_at: this.props.accountToSave?.effective_end_at,
                roles: user.roles,
            });
        } else {
            this.updateStateUser({
                first_name: '',
                last_name: '',
                email: '',
                locked: false,
                can_run_transactions: false,
                should_receive_sms: false,
                enforce_effective_dates: false,
                roles: [],
            });
        }
    }

    setEdited() {
        this.setState({ edited: true });
    }

    setIsValidPhoneNumber(inputPhoneVal) {
        const isValidPhoneNumber = phoneValidator(inputPhoneVal).isValid;
        if (isValidPhoneNumber) {
            this.setState({ isValidPhoneNumber: true });
        } else {
            this.setState({ isValidPhoneNumber: false });
        }
    }

    setCanSave(props) {
        return (
            !props.readOnly ||
            !props.accountToSave ||
            (props.loggedInUser &&
                props.loggedInUser.user_id === props.accountToSave.user_id)
        );
    }

    getPhoneNumberList() {
        const formattedPhoneNumbers = [];
        const inputPhoneNumbers = this.state.phoneNumbers;

        for (const inputNumber of inputPhoneNumbers) {
            if (inputNumber.phone !== '' && inputNumber.phone !== '(___) ___ - ____') {
                // Remove all parentheses, dashes, and spaces
                inputNumber.phone = inputNumber.phone
                    .replace('(', '')
                    .replace(')', '')
                    .replace('-', '')
                    .replace(/\s+/g, '');

                formattedPhoneNumbers.push(inputNumber);
            }
        }

        return formattedPhoneNumbers;
    }

    setEnableEffectiveDates(enabled) {
        this.updateStateUser({
            enforce_effective_dates: enabled,
        });
        this.setEdited();
    }

    setMFAModalState(type) {
        switch (type) {
            case 'reset':
                return this.setState({
                    isMFAModalOpen: true,
                    titleMFAModal: 'Reset MFA Confirmation',
                    bodyMFAModal: `Are you sure you want to reset MFA?\n\nThis action will unenroll MFA, User will be prompted to enroll using a multi-factor authentication app on the next login.`,
                    actionMFAModal: this.resetMFA.bind(this),
                });
            case 'backup':
                return this.setState({
                    isMFAModalOpen: true,
                    titleMFAModal: 'Generate New Backup Codes',
                    bodyMFAModal: `Are you sure you want to Generate New Backup Codes?\n\nThis action will delete previous Backup Codes generated, and regenerate new ones to be used by the User next time needed.`,
                    actionMFAModal: this.regenerateBackupCodes.bind(this),
                });
            case 'bypass':
                return this.setState({
                    isMFAModalOpen: true,
                    isMFABypassModalOpen: true,
                    titleMFAModal: 'Allow MFA Bypass',
                    bodyMFAModal: `Are you sure you want to disable multi-factor authentication (MFA) for this user?\n\nYou are required to enter a justification for this change.\n`,
                    actionMFAModal: this.saveAllowMFABypass,
                });
            default:
                break;
        }
        return null;
    }

    defaultRoleChange = (role) => {
        if (!role.is_default_role) {
            this.props.setDefaultRole(role);
        }
    };

    handleEditRoleClick = () => {
        this.props.editUserRoles(this.props.history);
    };

    handleEditAssignedConcessionairesClick = () => {
        this.props.updateConcAssignment(this.props.history);
    };

    countDifferences(obj1, obj2) {
        let differences = 0;
        const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
        keys.forEach((key) => {
            const val1 = obj1[key];
            const val2 = obj2[key];
            if (Array.isArray(val1) && Array.isArray(val2)) {
                if (val1.length !== val2.length) {
                    differences += 1;
                }
            } else if (val1 !== val2) {
                differences += 1;
            }
        });
        return differences;
    }

    save(event) {
        event.preventDefault();

        let user;

        if (!this.props.creating && this.props.readOnly) {
            user = {
                first_name: this.state.user.first_name,
                last_name: this.state.user.last_name,
                phone_numbers: this.getPhoneNumberList(),
            };
        } else {
            user = {
                first_name: this.state.user.first_name,
                last_name: this.state.user.last_name,
                phone_numbers: this.getPhoneNumberList(),
                email: this.state.user.email,
                locked: this.state.user.locked,
                can_run_transactions: this.state.user.can_run_transactions,
                is_concessionaire:
                    this.state.creatingConcessionaire ||
                    this.state.loggedInConcessionaireManager ||
                    this.state.loggedInConcessionaire,
                addresses: [],
                enforce_effective_dates: this.state.user.enforce_effective_dates,
                effective_start_at: this.state.user.effective_start_at,
                effective_end_at: this.state.user.effective_end_at,
            };
        }

        if (this.props.profile) {
            user.should_receive_sms = this.state.user.should_receive_sms;
        }

        if (!this.props.creating && this.props.accountToSave) {
            user = { ...this.props.accountToSave, ...user };
        }

        // simplify location objects on role list to bare minimum
        if (user.roles) {
            user.roles = user.roles.map((role) => {
                return {
                    role_type: role.role_type,
                    is_default_role: role.is_default_role,
                    permit_id: role.permit_id,
                    poc_type: role.poc_type,
                    location: {
                        location_id: role.location.location_id,
                        location_type: role.location.location_type,
                        has_lottery: role.location.has_lottery,
                        has_cooperators: role.location.has_cooperators,
                    },
                };
            });
        }

        if (this.props.creating) {
            const pageName = this.state.creatingConcessionaire
                ? 'edit-concessionaires'
                : 'edit-locations';

            this.props.continueFromCreate(user).then((forward) => {
                if (forward) {
                    this.props.history.push(`/internal/account/${pageName}`);
                }
            });
        } else {
            this.props.save(user);
        }
    }

    isUserConcessionaire(user) {
        return user && user.is_concessionaire && !user.inherits_concessionaire_roles;
    }

    isUserConcessionaireManager(user) {
        return user && user.is_concessionaire && user.inherits_concessionaire_roles;
    }

    successUI() {
        if (!this.props.creating && !this.props.error && this.props.savedAccount) {
            return (
                <Alert type="success">{`${this.props.savedAccount.email} was saved successfully.`}</Alert>
            );
        }

        return null;
    }

    addPhoneNumber() {
        this.setState((prevState) => {
            const phoneNumbers = prevState.phoneNumbers;
            phoneNumbers.push({
                phone: '',
                ext: '',
                type: 'cell',
            });
            return {
                phoneNumbers,
                changesCounter: this.countDifferences(
                    prevState.phoneNumbers ?? prevState.initialPhoneNumbers,
                    phoneNumbers
                ),
            };
        });
    }

    removePhoneNumber(index) {
        this.setState((prevState) => {
            const phoneNumbers = prevState.phoneNumbers;
            phoneNumbers.splice(index, 1);
            return {
                phoneNumbers,
                changesCounter: this.countDifferences(
                    prevState.phoneNumbers ?? prevState.initialPhoneNumbers,
                    phoneNumbers
                ),
            };
        });
    }

    createPhoneNumberListFromUser(props) {
        const myProps = props || this.props;
        let phoneNumbers = [];
        if (myProps.accountToSave && myProps.accountToSave.phone_numbers) {
            const user = myProps.accountToSave;
            phoneNumbers = user.phone_numbers.map((_phone) => {
                const phone = { ..._phone };
                const number = phone.phone;
                const formattedNum = `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6)}`;
                phone.phone = formattedNum;
                return phone;
            });
        } else {
            phoneNumbers = [];
        }
        return phoneNumbers;
    }

    isEditingConcessionaireManager() {
        const user = this.props.accountToSave;
        if (!user) {
            return false;
        }

        return (
            !this.props.loggedInUser.is_concessionaire &&
            this.isUserConcessionaireManager(user)
        );
    }

    updateConcButton() {
        if (!this.isEditingConcessionaireManager()) {
            return null;
        }

        return (
            <Button
                appearance="link"
                className="update-user-update-concessionaires-button"
                iconBeforeElement={<Icons.IconAddCircle />}
                onClick={this.handleEditAssignedConcessionairesClick}
            >
                Edit Assigned Concessionaires
            </Button>
        );
    }

    canEditLocations() {
        if (
            this.isEditingConcessionaireManager() ||
            (this.props.profile && doesUserHaveRoleType(this.props.loggedInUser, CSR1))
        ) {
            return false;
        }
        if (!this.props.profile && this.props.readOnly) {
            return false;
        }
        if (
            this.state.loggedInConcessionaire &&
            this.isUserConcessionaireManager(this.props.accountToSave)
        ) {
            return false;
        }
        return true;
    }

    concessionaireEntity() {
        if (this.props.accountToSave?.is_concessionaire) {
            if (this.props.concessionaires?.length > 0) {
                return (
                    <div className="assigned-concessionaires-wrapper mt-3">
                        <div className="assigned-concessionaires-header">
                            Associated Concessionaires
                        </div>
                        {this.updateConcButton()}
                        <ul className="assigned-concessionaires-list">
                            {this.props.concessionaires.map((conc) => (
                                <li key={`conc_${conc.concessionaire_id}`}>
                                    {conc.concessionaire_name}
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            }
            if (this.props.concessionaire) {
                // OLD KILL //
                return (
                    <div className="assigned-concessionaire-wrapper">
                        <div className="assigned-concessionaire-title">
                            Associated Concessionaire:
                            <span className="assigned-concessionaire-name">
                                {this.props.concessionaire.concessionaire_name}
                            </span>
                        </div>
                    </div>
                );
            } // OLD //
        }

        return null;
    }

    updateStateUser(user) {
        this.setState((prevState) => {
            return { user: { ...prevState.user, ...user } };
        });
    }

    isLoggedUserSameRoleAsUser(userA, userB) {
        if (userA.roles?.length > 0 && userB.roles?.length > 0) {
            const rolesArr1 = userA.roles.map((item) => item.role_type);
            const rolesArr2 = userB.roles.map((item) => item.role_type);

            return rolesArr1.some((roleType) => {
                if ([PMO, BAH_ADMIN, ATR].includes(roleType))
                    return rolesArr2.includes(roleType);

                return false;
            });
        }

        return null;
    }

    cbEffectiveDates(startDate, endDate) {
        this.updateStateUser({
            effective_start_at: startDate,
            effective_end_at: endDate,
        });
        this.setEdited();
    }

    progressRibbon(showProgress) {
        const type = this.state.creatingConcessionaire ? 'concessionaire' : 'default';
        const progress = showProgress ? (
            <EditUserProgress
                showUserDetails={this.props.creating}
                current="0"
                type={type}
            />
        ) : (
            ''
        );
        return (
            <div className="progress-ribbon-wrapper">
                {progress}
                <div className="user-management-button-header">
                    <ButtonGroup>
                        <Button
                            onClick={() => this.props.cancelUpdate(this.props.history)}
                            id="updateUserCancel"
                            appearance="tertiary"
                        >
                            Cancel
                        </Button>
                        {this.setCanSave(this.props) && (
                            <Button
                                appearance="primary"
                                isDisabled={!this.saveEnabled()}
                                onClick={this.save}
                                id="updateUserSave"
                            >
                                {this.props.creating ? 'Next' : 'Save'}
                            </Button>
                        )}
                    </ButtonGroup>
                </div>
            </div>
        );
    }

    saveEnabled() {
        if (!(this.state.edited || this.state.changesCounter > 0)) {
            return false;
        }

        if (!this.state.isValidPhoneNumber) {
            return false;
        }

        if (
            this.state.user.enforce_effective_dates &&
            (!this.state.user.effective_start_at || !this.state.user.effective_end_at)
        ) {
            // if the checkbox is selected, the dates must be set
            return false;
        }

        return true;
    }

    closeAlert() {
        this.setState({ showAlert: false });
    }

    closeModal() {
        this.setState({
            isMFAModalOpen: false,
            isMFABypassModalOpen: false,
            isNewMFABackupCodesModalOpen: false,
        });
    }

    resetMFA() {
        this.props.resetMFA(this.props.accountToSave.user_id);
        this.closeModal();
    }

    regenerateBackupCodes() {
        const renderBackupCodes = (response) => {
            return (
                <>
                    <div>
                        The following are new backup codes that the user can use to
                        authenticate during login.
                    </div>
                    {response.codes.map((code) => (
                        <pre>{code}</pre>
                    ))}
                </>
            );
        };
        this.props
            .postMfaBackupCodes(this.props.accountToSave.user_id)
            .then((response) => {
                this.setState({
                    isNewMFABackupCodesModalOpen: true,
                    isMFAModalOpen: true,
                    titleMFAModal: 'New MFA Backup Codes',
                    bodyMFAModal: renderBackupCodes(response),
                    actionMFAModal: this.closeModal,
                });
            });
        this.closeModal();
    }

    async saveAllowMFABypass() {
        const error = await this.props.setMFABypassFn(this.props.accountToSave?.user_id);
        if (error !== null) {
            this.props.createToast('Failed to set MFA bypass for the user', {
                type: 'error',
            });
        } else {
            this.props.addNoteForUpdate(
                `MFA bypass allowed: ${this.state.bypassMFAJustification}`,
                'MFA_BYPASS'
            );
            this.props.createToast('MFA bypass set successfully', {
                type: 'info',
            });
            this.setState({ bypassMFAJustification: '' });
        }
        this.closeModal();
    }

    showDeleteColumn() {
        if (this.props.readOnly) {
            return false;
        }
        if (this.props.accountToSave?.roles?.length < 2) {
            return false;
        }
        if (this.isEditingConcessionaireManager()) {
            return false;
        }
        if (this.isUserConcessionaireManager(this.props.accountToSave)) {
            return false;
        }
        return true;
    }

    renderColumnsLayout(
        enableConc,
        enforceEffectiveDates,
        effectiveStartAt,
        effectiveEndAt,
        managingInternalUsersGuideLink
    ) {
        const pendingConfirmation =
            !this.props.creating &&
            !isNil(this.props.accountToSave?.email_change) &&
            isNil(this.props.accountToSave?.email_change.completed_at);

        return (
            <div className="rec-section-outer-wrap">
                <div className="rec-nested-wrap">
                    {isEqual(this.props.successForCancel, false) &&
                        this.state.showAlert && (
                            <Alert
                                className="mb-3"
                                type="error"
                                showCloseButton
                                heading="Error"
                                onCloseButtonClick={this.closeAlert}
                            >
                                Cancel email change request failed!
                            </Alert>
                        )}
                    {isEqual(this.props.successForCancel, true) &&
                        this.state.showAlert && (
                            <Alert
                                className="mb-3"
                                type="success"
                                showCloseButton
                                heading="Success!"
                                onCloseButtonClick={this.closeAlert}
                            >
                                Cancel email change request completed!
                            </Alert>
                        )}
                    {this.props.creating && (
                        <Heading appearance="h5" headingLevel={2} className="mb-2">
                            Create User
                        </Heading>
                    )}
                    <FlexRow>
                        <FlexCol sm={6}>
                            <TextFieldStateless
                                id="updateUserFirstName"
                                label="First Name"
                                value={this.state.user.first_name}
                                onChange={(e) => {
                                    this.setEdited();
                                    this.updateStateUser({
                                        first_name: e.target.value,
                                    });
                                }}
                                placeholder="First Name"
                                isRequired
                                isDisabled={!this.setCanSave(this.props)}
                            />
                            <Spacer size="sm" />
                            <TextFieldStateless
                                label="Last Name"
                                value={this.state.user.last_name}
                                onChange={(e) => {
                                    this.setEdited();
                                    this.updateStateUser({
                                        last_name: e.target.value,
                                    });
                                }}
                                placeholder="Last Name"
                                isRequired
                                isDisabled={!this.setCanSave(this.props)}
                                id="updateUserLastName"
                            />
                            <Spacer size="sm" />
                            <EmailField
                                value={this.state.user.email}
                                onChange={(e) => {
                                    this.setEdited();
                                    this.updateStateUser({
                                        email: e.target.value,
                                    });
                                }}
                                isRequired
                                isDisabled={this.state.readOnly || this.props.profile}
                                id="updateUserEmail"
                            />
                            <Spacer size="sm" />
                            {!this.props.creating && pendingConfirmation && (
                                <>
                                    <div className="pending-confirmation-label">
                                        Requested (Pending confirmation)
                                    </div>
                                    <div className="pending-confirmation-email">
                                        {this.props.accountToSave?.email}
                                    </div>
                                    <Spacer size="sm" />
                                    <Button
                                        appearance="tertiary"
                                        onClick={() => {
                                            this.setState({ showAlert: true });
                                            this.props.cancelEmailChange(
                                                this.props.accountToSave?.user_id
                                            );
                                            this.props.emailChangeCancelled();
                                        }}
                                    >
                                        Cancel Email Change Request
                                    </Button>
                                    <Spacer size="sm" />
                                </>
                            )}

                            {this.props.profile && (
                                <Link
                                    className="update-user-change-password-link"
                                    to="/internal/account/change-password"
                                >
                                    Change Password
                                </Link>
                            )}

                            <fieldset>
                                <div>
                                    <Checkbox
                                        className="hidden"
                                        isChecked={this.state.user.can_run_transactions}
                                        onChange={() =>
                                            this.updateStateUser({
                                                can_run_transactions:
                                                    !this.state.user.can_run_transactions,
                                            })
                                        }
                                        label="Can Run Transactions"
                                        isDisabled={this.props.readOnly}
                                        id="can_run_transactions"
                                    />
                                    <Checkbox
                                        isDisabled={!enableConc}
                                        isChecked={
                                            this.state.creatingConcessionaire ||
                                            this.state.loggedInConcessionaire
                                        }
                                        onChange={() => {
                                            this.setEdited();
                                            this.setState((prevState) => {
                                                return {
                                                    creatingConcessionaire:
                                                        !prevState.creatingConcessionaire,
                                                };
                                            });
                                        }}
                                        label={
                                            (this.props.profile &&
                                                this.state.loggedInConcessionaire) ||
                                            (!this.props.profile &&
                                                this.isUserConcessionaire(
                                                    this.props.accountToSave
                                                ))
                                                ? 'Concessionaire'
                                                : 'Concessionaire Facility Manager'
                                        }
                                        id="concessionaire"
                                    />
                                    {this.props.profile && (
                                        <>
                                            <Spacer size="sm" />
                                            <Checkbox
                                                isChecked={
                                                    this.state.user.should_receive_sms
                                                }
                                                onChange={() => {
                                                    this.setEdited();
                                                    if (
                                                        !this.state.user
                                                            .should_receive_sms &&
                                                        this.state.phoneNumbers.filter(
                                                            (phone) =>
                                                                phone.type === 'cell'
                                                        ).length === 0
                                                    ) {
                                                        this.addPhoneNumber.bind(this);
                                                    }
                                                    this.updateStateUser({
                                                        should_receive_sms:
                                                            !this.state.user
                                                                .should_receive_sms,
                                                    });
                                                }}
                                                label="Receive SMS (Text)"
                                                id="should_receive_sms"
                                            />
                                            <Spacer size="sm" />
                                        </>
                                    )}
                                </div>
                            </fieldset>
                            <PhoneNumbers
                                phoneNumbers={this.state.phoneNumbers}
                                canSave={this.setCanSave(this.props)}
                                onExtChange={(event, index) => {
                                    this.onPhoneExtensionChange(index, event);
                                }}
                                onTypeChange={(type, index) => {
                                    this.onPhoneTypeChange(index, type);
                                }}
                                onNumberChange={(event, index) => {
                                    this.setIsValidPhoneNumber(
                                        this.state.phoneNumbers[index].phone
                                    );
                                    this.onPhoneNumberChange(index, event);
                                }}
                                onRemovePhone={this.removePhoneNumber.bind(this)}
                                onAddPhone={this.addPhoneNumber.bind(this)}
                            />
                            {!this.props.creating && (
                                <FeatureFlag flag="iaEnableMfa">
                                    <div className="update-user-roles-table-header">
                                        Multi-factor Authentication Enrollment Status
                                    </div>
                                    <div className="mfa-enrollment-status">
                                        {this.state.userMFAStatus}
                                    </div>
                                    {this.state.canControlMFA && (
                                        <ButtonGroup isStretchedToFit>
                                            <Button
                                                appearance="tertiary"
                                                gaTrackingId="987944634163"
                                                onClick={() =>
                                                    this.setMFAModalState('reset')
                                                }
                                            >
                                                Reset MFA
                                            </Button>
                                            <Button
                                                appearance="tertiary"
                                                gaTrackingId="987944634163"
                                                onClick={() =>
                                                    this.setMFAModalState('backup')
                                                }
                                            >
                                                Generate New Back Up Codes
                                            </Button>
                                            {this.state.canDoMFABypass && (
                                                <Button
                                                    gaTrackingId="987944634163"
                                                    appearance="tertiary"
                                                    onClick={() =>
                                                        this.setMFAModalState('bypass')
                                                    }
                                                >
                                                    Allow MFA Bypass
                                                </Button>
                                            )}
                                        </ButtonGroup>
                                    )}
                                </FeatureFlag>
                            )}
                            {this.concessionaireEntity()}
                            {!this.props.creating && (
                                <RolesTable
                                    showDefaultColumn={
                                        this.props.profile &&
                                        this.props.accountToSave?.roles?.length > 1
                                    }
                                    showDeleteColumn={this.showDeleteColumn()}
                                    canEditLocations={this.canEditLocations()}
                                    onEditLocations={this.handleEditRoleClick}
                                    onRoleDelete={this.props.deleteRole}
                                    onDefaultRoleChange={this.defaultRoleChange}
                                    editingSelf={
                                        this.props.loggedInUser.user_id ===
                                        this.props.accountToSave?.user_id
                                    }
                                    roles={this.props.accountToSave?.roles}
                                />
                            )}
                        </FlexCol>
                        <FlexCol sm={6}>
                            <div className="nested-grid-inner-wrap mb-3">
                                <div className="rec-form-check-wrap">
                                    <div className="rec-grid-9-12 mt-1">
                                        <Switch
                                            label={
                                                !this.state.user.locked
                                                    ? 'Active'
                                                    : 'Locked'
                                            }
                                            isSelected={!this.state.user.locked}
                                            onChange={() => {
                                                this.updateStateUser({
                                                    locked: !this.state.user.locked,
                                                });
                                                this.setEdited();
                                            }}
                                            isDisabled={this.props.readOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                            <FlexRow>
                                <EffectiveDates
                                    editable={
                                        !(this.state.readOnly || this.props.profile)
                                    }
                                    effectiveDatesEnabled={enforceEffectiveDates}
                                    inStartDate={effectiveStartAt}
                                    inEndDate={effectiveEndAt}
                                    setDatesFn={this.cbEffectiveDates}
                                    setEnabledFn={this.setEnableEffectiveDates}
                                />
                                <div className="how-it-works-link">|</div>
                                <a
                                    href={managingInternalUsersGuideLink}
                                    className="how-it-works-link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    How does this work?{' '}
                                </a>
                                <span className="how-it-works-icon">
                                    <Icons.IconHelp size="sm" />
                                </span>
                            </FlexRow>
                            <div className="rec-form-item-wrap mt-3 mb-4">
                                {!this.props.creating && (
                                    <UserNotes
                                        readOnly={
                                            this.state.isCsrSupervisor
                                                ? false
                                                : this.props.readOnly
                                        }
                                    />
                                )}
                            </div>
                            {this.isLoggedUserSameRoleAsUser(
                                this.props.loggedInUser,
                                this.state.user
                            ) === false && (
                                <>
                                    <ResetPassword
                                        resetPassword={this.props.resetPassword}
                                        errorEnablingPasswordReset={
                                            this.props.errorEnablingPasswordReset
                                        }
                                        userIdEnabledPasswordReset={
                                            this.props.userIdEnabledPasswordReset
                                        }
                                        profile={this.props.profile}
                                        creating={this.props.creating}
                                        accountToSave={this.props.accountToSave}
                                    />
                                    <ResendConfirmEmail
                                        accountToSave={this.props.accountToSave}
                                        creating={this.props.creating}
                                        requestConfirmResponse={
                                            this.props.requestConfirmResponse
                                        }
                                        resendConfirmation={this.props.resendConfirmation}
                                        pendingConfirmation={pendingConfirmation}
                                    />
                                </>
                            )}
                            {!this.props.creating && (
                                <AdditionalInformation
                                    user={{
                                        ...this.props.accountToSave,
                                        enforce_effective_dates: enforceEffectiveDates,
                                        effective_start_at: effectiveStartAt,
                                        effective_end_at: effectiveEndAt,
                                    }}
                                />
                            )}
                        </FlexCol>
                    </FlexRow>
                </div>
            </div>
        );
    }

    render() {
        const { creating } = this.props;
        const createHide =
            creating &&
            !this.state.canCreateConcessionaireManager &&
            !this.state.canCreateConcessionaire;
        const profileHide = this.props.profile && !this.state.loggedInConcessionaire;
        const enableConc =
            creating &&
            !createHide &&
            !profileHide &&
            this.state.canCreateConcessionaireManager;
        const enforceEffectiveDates = this.state.user?.enforce_effective_dates;
        const effectiveStartAt = !isEmpty(this.state.user?.effective_start_at)
            ? this.state.user?.effective_start_at
            : null;
        const effectiveEndAt = !isEmpty(this.state.user?.effective_end_at)
            ? this.state.user?.effective_end_at
            : null;
        const managingInternalUsersGuideLink = `${process.env.SN_KB_INT_URL}?id=kb_article_view&sys_kb_id=8db38e73dbc86300c571740d0f9619d1#dates`;

        return (
            <div>
                <div className="edit-user-wrapper">
                    {this.progressRibbon(creating)}
                    <div id="page-body">
                        {this.successUI()}
                        <ActionErrorDisplay
                            error={this.props.error}
                            errorStringMapping={errorForUpdateUser}
                        />
                        {this.renderColumnsLayout(
                            enableConc,
                            enforceEffectiveDates,
                            effectiveStartAt,
                            effectiveEndAt,
                            managingInternalUsersGuideLink
                        )}
                    </div>
                </div>
                <FeatureFlag flag="iaEnableMfa">
                    <StyledModal
                        size="sm"
                        isOpen={this.state.isMFAModalOpen}
                        heading={this.state.titleMFAModal}
                        onRequestClose={this.closeModal}
                    >
                        <div className="modal-mfa-text-body">
                            {this.state.bodyMFAModal}
                        </div>
                        {this.state.isMFABypassModalOpen && (
                            <TextArea
                                placeholder="Write the justification here:"
                                id="justification"
                                label=""
                                value={this.state.bypassMFAJustification}
                                onChange={(e) =>
                                    this.setState({
                                        bypassMFAJustification: e.target.value,
                                    })
                                }
                            />
                        )}
                        <ModalActions>
                            <ButtonGroup isFullWidthOnMobile={false} isStretchedToFit>
                                {!this.state.isNewMFABackupCodesModalOpen && (
                                    <Button
                                        appearance="tertiary"
                                        onClick={this.closeModal}
                                        gaTrackingId="728118717041"
                                    >
                                        Cancel
                                    </Button>
                                )}
                                <Button
                                    onClick={this.state.actionMFAModal}
                                    gaTrackingId="728118717041"
                                    disabled={
                                        this.state.isMFABypassModalOpen &&
                                        this.state.bypassMFAJustification.length <= 1
                                    }
                                >
                                    {this.state.isMFABypassModalOpen
                                        ? 'Confirm MFA Bypass'
                                        : 'Okay'}
                                </Button>
                            </ButtonGroup>
                        </ModalActions>
                    </StyledModal>
                </FeatureFlag>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        creatingUser: state.updateUser.creating,
        editing: state.userManagement.selectedTabIndex === 2,
        accountToSave: state.updateUser.accountToSave,
        savedAccount: state.updateUser.accountSaved,
        creating: state.updateUser.creating,
        error: state.updateUser.error,
        reloadAccount: state.updateUser.reloadAccount,
        readOnly: state.updateUser.readOnly,
        loggedInUser: state.login ? state.login.user : null,
        requestConfirmResponse: state.updateUser.requestConfirmResponse,
        userIdEnabledPasswordReset: state.updateUser.userIdEnabledPasswordReset,
        errorEnablingPasswordReset: state.updateUser.errorEnablingPasswordReset,
        // OLD KILL //
        concessionaire: state.fetchManagedConcessionaires.assignedConcessionaire,
        // OLD //
        concessionaires: state.updateUser.assignedConcessionaires,
        successForCancel: state.confirmEmail?.successForCancel,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        editUserRoles: () => dispatch(editUserRoles(ownProps.history)),
        updateConcAssignment: (history) => dispatch(updateConcAssignment(history)),
        deleteRole: (role) => dispatch(deleteRole(role)),
        save: (user) => dispatch(updateUser(user)),
        continueFromCreate: (user, doConcessionaire) =>
            dispatch(continueFromCreatingUser(user, doConcessionaire)),
        cancelUpdate: (history) => dispatch(cancelUpdate(history)),
        setDefaultRole: (role) => dispatch(setDefaultRole(role)),
        cancelEmailChange: (userId) => dispatch(cancelEmailChange(userId)),
        emailChangeCancelled: () => dispatch(emailChangeCancelled()),
        resendConfirmation: (user) => {
            dispatch(resendConfirmation(user));
            dispatch(cancelEmailChangeReset());
        },
        resetPassword: (userId) => dispatch(enablePasswordReset(userId)),
        setMFABypassFn: (userID) => dispatch(setMFABypass(userID)),
        resetMFA: (userID) => dispatch(resetMFA(userID)),
        postMfaBackupCodes: (userID) => postMfaBackupCodes(dispatch, userID),
        createToast: (toastObject) => dispatch(showToastAction(toastObject)),
        addNoteForUpdate: (text, reason) =>
            dispatch(addNoteForExistingUser(text, reason)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser);

/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import * as types from '../constants/types';
import * as errors from '../constants/errors';
import * as globals from '../constants/globals';
import { doneLoading, startLoading } from './loading';
import {
    claimIsSingleFactor,
    setAuthStorage,
    getAccountWithToken,
} from '../utilities/internalSecurityUtil';
import { makeCompatNavigate } from '../utilities/compatNavigateUtil';

const idleMonitorStorageKey = 'idlemonitor';
const inactivityTimeout = 30 * 60 * 1000;
export const loginMessagesStorageKey = 'loginMessages';
export const JSONContentType = 'application/json; charset=utf-8';
export const MFAFactors = Object.freeze({
    Any: 0,
    Mock: 1,
    TOTP: 2,
    BackupCodes: 3,
});

export const loginDotGovUnlinkedLoggedInUser = () => {
    return { type: types.LOGIN_GOV_UNLINKED_MY_PROFILE };
};

const closeSiteWideAlert = (alert_id) => {
    return {
        type: types.CLOSE_SITE_WIDE_ALERT,
        alert_id,
    };
};

export const setEmail = (email) => {
    return {
        type: types.SET_EMAIL,
        email,
    };
};

export const displayAutoLogoutWarning = () => {
    return {
        type: types.DISPLAY_AUTO_LOGOUT_WARNING,
    };
};

export const closeAutoLogoutWarning = () => {
    return {
        type: types.CLOSE_AUTO_LOGOUT_WARNING,
    };
};

const loggedOut = () => {
    return {
        type: types.LOGGED_OUT,
    };
};

const fetchingAlertsFailed = () => {
    return {
        type: types.FETCHING_ALERTS_FAILED,
    };
};

const alertsFetched = (alerts) => {
    return {
        type: types.ALERTS_FETCHED,
        alerts,
    };
};

const mustSetPassword = (token, history) => {
    const navigate = makeCompatNavigate(history);
    navigate(`/internal/account/reset-password?token=${token}`);
    return {
        type: types.LOGGED_OUT_FOR_MUST_SET_PASSWORD,
    };
};

export const loginFailed = (error) => {
    return {
        type: types.LOGIN_FAILED,
        error,
    };
};

export const clearError = () => {
    return {
        type: types.CLEAR_LOGIN_ERROR,
    };
};

export const foundNewUser = () => {
    return {
        type: types.FOUND_NEW_USER,
    };
};

//Should probably only be called by the logout action unless not logged in. Fight me!
//Also note that this gets overwritten by LEGACY_InternalProtectedRoute in most cases so if stuff is weird look there.
export const navigateLogin = (prevPathname) => {
    let redirectTo = globals.LOGIN_PATH;

    if (prevPathname && prevPathname !== globals.LOGIN_PATH) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('redirect', prevPathname);
        const urlParam = `?${searchParams.toString()}`;
        redirectTo = `${globals.LOGIN_PATH}${urlParam}`;
    }

    window.location.assign(redirectTo);
};

export const getTokenFromStorage = () => {
    const tokenString = localStorage.getItem(globals.TOKEN_LOCAL_STORAGE_KEY);
    if (!tokenString) {
        navigateLogin(window.location.pathname);
        throw new Error('No token stored in local storage');
    }

    const tokenObject = JSON.parse(tokenString);
    if (
        !tokenObject.access_token ||
        jwtDecode(tokenObject.access_token).exp < Date.now() / 1000
    ) {
        localStorage.removeItem(globals.TOKEN_LOCAL_STORAGE_KEY);
        navigateLogin(window.location.pathname);
        throw new Error('Token has expired');
    }

    return tokenObject;
};

const buildHeadersAndMethod = (tokenObject, methodParam) => {
    const headers = {
        Authorization: `Bearer ${tokenObject.access_token}`,
    };
    const method = methodParam.toUpperCase();
    if (
        method === 'POST' ||
        method === 'PUT' ||
        method === 'DELETE' ||
        method === 'PATCH'
    ) {
        headers['content-type'] = JSONContentType;
    }

    return { headers, method };
};

const authorizedRequest = async (url, methodParam, data, responseType) => {
    try {
        const tokenObject = getTokenFromStorage();
        const { headers, method } = buildHeadersAndMethod(tokenObject, methodParam);

        await axios({ url, method, headers, data, responseType, credentials: 'include' });
    } catch (error) {
        // Todo: Log to Sentry
    }
};

export const logOut = (prevPathname) => {
    // todo: find other uses of this function and make them dispatch
    return (dispatch) => {
        authorizedRequest(`${globals.API_URL}/logout`, 'POST', {});
        localStorage.removeItem(globals.TOKEN_LOCAL_STORAGE_KEY);
        localStorage.removeItem('persist:root');
        if (window.sessionStorage) {
            window.sessionStorage.clear();
        }
        navigateLogin(prevPathname);
        dispatch(loggedOut());
    };
};

export const forceLogOut = (error) => {
    return (dispatch) => {
        dispatch(logOut(window.location.pathname));
        dispatch(loginFailed(error));
    };
};

const handleAuthorizedRequestError = (dispatch, error) => {
    const errorResponse = error.response;
    let throwError;

    if (errorResponse && errorResponse.data.error) {
        if (errorResponse.data.error.indexOf(errors.CONCURRENT_SESSION_ERROR) !== -1) {
            const loginMessages = JSON.stringify({ concurrentSessionError: true });
            localStorage.setItem(loginMessagesStorageKey, loginMessages);

            dispatch(forceLogOut(errors.CONCURRENT_SESSION_ERROR));
        } else if (errorResponse.data.error.indexOf(errors.EMAIL_NOT_CONFIRMED) !== -1) {
            dispatch(forceLogOut(errors.EMAIL_NOT_CONFIRMED));
        } else if (errorResponse.status === 401) {
            dispatch(forceLogOut(errors.NOT_AUTHORIZED));
        } else if (
            errorResponse.data.time_to_live &&
            errorResponse.data.error.indexOf(errors.RESEND_CONFIRMATION_ALREADY_SENT) !==
                -1
        ) {
            // For resend confirmation email
            throwError = errorResponse.data;
        } else {
            throwError = new Error(errorResponse.data.error);
        }
    } else {
        throwError = error;
    }

    return throwError;
};

export const makeAuthorizedRequest = async (
    url,
    methodParam,
    dispatch,
    data,
    responseType
) => {
    const tokenObject = getTokenFromStorage();
    const { headers, method } = buildHeadersAndMethod(tokenObject, methodParam);

    let throwError;
    const response = await axios({
        url,
        method,
        headers,
        data,
        responseType,
        credentials: 'include',
    }).catch((error) => {
        throwError = handleAuthorizedRequestError(dispatch, error);
    });

    if (throwError) {
        throw throwError;
    } else {
        return response?.data;
    }
};

export const fetchAlerts = (location) => {
    return async (dispatch) => {
        try {
            const id = location.location_id || location.location_attributes.id;
            const type = location.location_type || location.location_attributes.type;
            const query = `?location_id=${encodeURIComponent(id)}&location_type=${encodeURIComponent(type)}`;
            const url = `/api/communication/internal/alert/display${query}`;

            const response = await makeAuthorizedRequest(url, 'GET', dispatch);
            const alerts = response.alerts;

            dispatch(alertsFetched(alerts));
        } catch (error) {
            fetchingAlertsFailed();
        }
    };
};

export const loginSuccessful = (account) => {
    return (dispatch, getState) => {
        let selectedRole;
        const state = getState();

        if (state.selectedLocationRole && state.selectedLocationRole.role) {
            selectedRole = state.selectedLocationRole.role;
        } else {
            let persistedRole;

            const persistedRoleString = localStorage.getItem(
                `persist:${globals.PERSIST_KEY}:selectedLocationRole`
            );
            if (persistedRoleString) {
                try {
                    persistedRole = JSON.parse(persistedRoleString);
                } catch (err) {
                    persistedRole = false;
                }
            }

            if (persistedRole && persistedRole.role) {
                try {
                    selectedRole = JSON.parse(persistedRole.role);
                } catch (err) {
                    selectedRole = false;
                }
            }
        }

        if (!selectedRole) {
            let initiallySelectedRole = account.user.roles.find((r) => r.is_default_role);

            if (!initiallySelectedRole) {
                initiallySelectedRole =
                    account.user.roles.length > 0 ? account.user.roles[0] : null;
            }

            selectedRole = initiallySelectedRole;
        }

        if (selectedRole) {
            dispatch(fetchAlerts(selectedRole.location));
        }

        dispatch({
            type: types.LOGIN_SUCCESSFUL,
            initiallySelectedRole: selectedRole,
            account,
        });
    };
};

export const logOutForInactivity = () => {
    return (dispatch) => {
        dispatch(logOut(window.location.pathname));
        dispatch({
            type: types.LOGGED_OUT_FOR_INACTIVITY,
        });
    };
};

export const checkLoggedIn = (
    fromLocation,
    history,
    isOidc = false,
    isConfirmFlow = false
) => {
    return (dispatch, getState) => {
        const navigate = makeCompatNavigate(history);
        if (isConfirmFlow) {
            navigate('/internal/account/confirm-email-change');
            return;
        }

        if (isOidc) {
            return;
        }

        const state = getState();

        const pathName = window.location.pathname;
        if (globals.EXCLUDE_LOGIN_PATHS.indexOf(pathName) >= 0) {
            return;
        }

        const lastActiveEntryString = localStorage.getItem(idleMonitorStorageKey);
        const inactivityLimit = state.login?.account?.inactivity_limit_seconds;

        if (lastActiveEntryString) {
            const il = inactivityLimit ? inactivityLimit * 1000 : inactivityTimeout;
            const lastActiveEntry = JSON.parse(lastActiveEntryString);
            const lastActivePastLimit = lastActiveEntry.lastActive + il < Date.now();

            if (
                pathName !== globals.LOGIN_PATH &&
                (!lastActiveEntry.isActive || lastActivePastLimit)
            ) {
                const loginMessages = JSON.stringify({ loggedOutForInactivity: true });
                localStorage.setItem(loginMessagesStorageKey, loginMessages);
                dispatch(logOutForInactivity());
            }
        }

        const tokenString = localStorage.getItem(globals.TOKEN_LOCAL_STORAGE_KEY);
        const tokenObject = tokenString ? JSON.parse(tokenString) : null;

        const invalid =
            !tokenObject ||
            !tokenObject.access_token ||
            !jwtDecode(tokenObject.access_token).exp >= Date.now() / 1000;
        const needsToSetPwdForFistTime =
            tokenObject &&
            tokenObject.user &&
            !tokenObject.user.oidc_session &&
            (tokenObject.user.password_set_at === globals.EMPTY_TIME_STRING ||
                tokenObject.user.password_set_at == null);
        const needsToChangePwd =
            tokenObject &&
            tokenObject.user &&
            !tokenObject.user.oidc_session &&
            tokenObject.user.must_set_password;

        if (invalid) {
            if (
                !pathName.startsWith(globals.REPORT_DOWNLOAD_PATH) &&
                pathName !== globals.LOGIN_PATH
            ) {
                dispatch(logOut(fromLocation ? fromLocation.pathname : '/'));
            }
            return;
        }

        if (!invalid && needsToSetPwdForFistTime) {
            dispatch(mustSetPassword(tokenObject.access_token, history));
            return;
        }

        if (!invalid && needsToChangePwd) {
            dispatch(loginSuccessful(tokenObject));
            dispatch(foundNewUser());
            navigate('/internal/account/change-password');
            return;
        }

        if (claimIsSingleFactor(tokenObject?.access_token)) {
            return;
        }

        if (
            !invalid &&
            !needsToSetPwdForFistTime &&
            !needsToChangePwd &&
            pathName === globals.LOGIN_PATH &&
            history
        ) {
            // user is logged in, doesn't need to set or change a password, and we are on the login page.  Likely we are here
            // because the user had a bookmark or manually navigated to the login page. Send them to the dashboard
            navigate(globals.DASHBOARD_PATH);
            return;
        }

        if (!state.login.user) {
            dispatch(loginSuccessful(tokenObject));
            return;
        }

        if (state.login.user && state.login.user.user_id !== tokenObject.user.user_id) {
            dispatch(loginSuccessful(tokenObject));
            dispatch(foundNewUser());
        }
    };
};

export const loggedIn = (
    loginData,
    nextPathname,
    history,
    isOidc = false,
    isConfirmFlow = false
) => {
    return (dispatch) => {
        const ld = { ...loginData };
        if (isOidc) {
            ld.user.oidc_session = true;
        }
        setAuthStorage(ld);
        dispatch(loginSuccessful(ld));
        const navigate = makeCompatNavigate(history);

        if (
            ld?.user?.must_set_password &&
            ld?.user?.password_set_at !== globals.EMPTY_TIME_STRING &&
            ld?.user?.password_set_at != null
        ) {
            navigate('/internal/account/change-password');
        } else if (nextPathname && typeof nextPathname === 'string') {
            //needs to be window.location.assign because history.push doesn't work across microservices.
            if (!nextPathname.includes('/internal/account/confirm-email-change')) {
                window.location.assign(nextPathname);
            }
        } else {
            navigate('/internal/account/hub');
        }

        dispatch(checkLoggedIn(null, history, isOidc, isConfirmFlow));
    };
};

export const doMfaValidate = (code, nextPathname, history) => {
    return async (dispatch) => {
        dispatch(startLoading('Verifying...'));
        try {
            const payload = {
                token: code,
                factor: MFAFactors.Any,
            };
            const response = await makeAuthorizedRequest(
                `${globals.API_URL}/mfa/validate`,
                'POST',
                dispatch,
                payload
            );
            dispatch(loggedIn(response, nextPathname, history));
        } catch (error) {
            dispatch(loginFailed(error));
            return;
        } finally {
            dispatch(doneLoading());
        }

        const setActiveItem = JSON.stringify({ isActive: true, lastActive: Date.now() });
        localStorage.setItem(idleMonitorStorageKey, setActiveItem);
        localStorage.removeItem(loginMessagesStorageKey);
        localStorage.removeItem(types.ClosedSiteWideBannerMap);
    };
};

export const ensureLoggedInUserInState = (history) => {
    return (dispatch, getState) => {
        const state = getState();

        if (!state.login.user) {
            const tokenString = localStorage.getItem(globals.TOKEN_LOCAL_STORAGE_KEY);
            const tokenObject = tokenString ? JSON.parse(tokenString) : null;

            if (tokenObject) {
                dispatch(loginSuccessful(tokenObject));
                dispatch(foundNewUser());
            } else {
                const navigate = makeCompatNavigate(history);
                navigate('/internal/account/login', { replace: true });
            }
        }
    };
};

export const forgotPassword = (email, history) => {
    const navigate = makeCompatNavigate(history);
    navigate('/internal/account/recover-password');
    return (dispatch) => {
        dispatch(setEmail(email));
    };
};

export const redirectToMfaPrompt = (loginResponseData) => {
    return async (dispatch) => {
        setAuthStorage(loginResponseData);
        dispatch({
            type: types.MFA_VALIDATE_REQUIRED,
        });
    };
};

export const redirectToMfaEnroll = (history, loginResponseData) => {
    const navigate = makeCompatNavigate(history);
    navigate('/internal/account/login');
    return async (dispatch) => {
        setAuthStorage(loginResponseData);
        dispatch({
            type: types.MFA_ENROLL_REQUIRED,
            mfa_opt_out_allowed: loginResponseData?.mfa_opt_out_allowed,
        });
    };
};

export const resetIdleMonitor = () => {
    const activeItem = JSON.stringify({ isActive: true, lastActive: Date.now() });
    localStorage.setItem(idleMonitorStorageKey, activeItem);
    localStorage.removeItem(loginMessagesStorageKey);
    localStorage.removeItem(types.ClosedSiteWideBannerMap);
};

export const logIn = (email, password, nextPathname, history) => {
    return async (dispatch) => {
        const postData = {
            email,
            password,
        };

        const tokenString = localStorage.getItem(globals.TOKEN_LOCAL_STORAGE_KEY);
        const tokenObject = tokenString ? JSON.parse(tokenString) : null;
        if (tokenObject) {
            // This must be cleared in the case that a user has two login screens open.
            // The user could potentially login with one, do some work, walk away, come back and log in
            // with the other. This will clear the previously selected role and set the new login state
            // to either the default role or the 0th role. -jkeene
            localStorage.removeItem(
                `persist:${globals.PERSIST_KEY}:selectedLocationRole`
            );
        }
        try {
            dispatch(startLoading('Signing In...'));

            resetIdleMonitor();

            const headers = new Headers();
            headers.append('Content-Type', JSONContentType);

            const response = await axios.post(`${globals.API_URL}/login`, postData, {
                withCredentials: true,
                credentials: 'include',
                headers,
            });

            const loginResponseData = response?.data;

            if (response.status >= 200 && response.status < 300) {
                dispatch(clearError());
                if (claimIsSingleFactor(loginResponseData.access_token)) {
                    if (loginResponseData.should_enroll_mfa) {
                        dispatch(redirectToMfaEnroll(history, loginResponseData));
                    } else {
                        dispatch(redirectToMfaPrompt(loginResponseData));
                    }
                } else {
                    const isConfirmEmailChange = nextPathname?.includes(
                        '/internal/account/confirm-email-change'
                    );
                    if (isConfirmEmailChange) {
                        loginResponseData.user.confirmEmailChangeVerified = true;
                    }
                    //this route should not be redirected to, it's already enforced at the loggedIn fn
                    if (nextPathname?.includes('/internal/account/change-password')) {
                        dispatch(loggedIn(loginResponseData, null, history));
                    } else {
                        dispatch(
                            loggedIn(
                                loginResponseData,
                                nextPathname,
                                history,
                                null,
                                isConfirmEmailChange
                            )
                        );
                    }
                }
            } else {
                throw new Error(loginResponseData.error);
            }
        } catch (error) {
            dispatch(loginFailed(error));
        } finally {
            dispatch(doneLoading());
        }
    };
};

export const autoLogoutWarningCanceled = () => {
    return (dispatch) => {
        dispatch(closeAutoLogoutWarning());
        dispatch(logOut(window.location.pathname));
    };
};

export const closeSiteWideBanner = (alert_id) => {
    try {
        const closedSiteWideIDs = JSON.parse(
            window.localStorage.getItem(types.ClosedSiteWideBannerMap) || '{}'
        );
        window.localStorage.setItem(
            types.ClosedSiteWideBannerMap,
            JSON.stringify({ ...closedSiteWideIDs, [alert_id]: true })
        );
    } catch (e) {
        //TODO ignoring error apparently. Handle this properly
    }
    return closeSiteWideAlert(alert_id);
};

export const updateUserAtStorage = (updatedUser) => {
    const accountFromLocalStorage = getAccountWithToken();
    const accountUpdate = { ...accountFromLocalStorage, user: updatedUser };
    localStorage.setItem(globals.TOKEN_LOCAL_STORAGE_KEY, JSON.stringify(accountUpdate));
};

export const updateSession = (user, history) => {
    return (dispatch, getState) => {
        const state = getState();

        const updatedAccount = { ...state.login.account, user };

        updateUserAtStorage(updatedAccount.user);

        const navigate = makeCompatNavigate(history);
        navigate('/internal/account/hub');
        dispatch(checkLoggedIn(null, history));
    };
};

export const resetSession = () => {
    return (dispatch) => {
        dispatch(clearError());
        dispatch(loggedOut());
        resetIdleMonitor();
        localStorage.removeItem(globals.TOKEN_LOCAL_STORAGE_KEY);
        localStorage.removeItem('persist:root');
        localStorage.removeItem(`persist:${globals.PERSIST_KEY}:selectedLocationRole`);
        if (window.sessionStorage) {
            window.sessionStorage.clear();
        }
    };
};

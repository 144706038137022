/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

const routes = [];

routes[routes.length] = {
    path: '/internal/account/hub',
    componentName: 'LaunchPad',
};

routes[routes.length] = {
    path: '/internal/account/site-map',
    componentName: 'SiteMap',
};

routes[routes.length] = {
    path: '/internal/account/login',
    componentName: 'LoginPage',
    isPublic: true,
};

routes[routes.length] = {
    path: '/internal/account/recover-password',
    componentName: 'RecoverPasswordPage',
    isPublic: true,
};

routes[routes.length] = {
    path: '/internal/account/reset-password',
    componentName: 'ResetPasswordPage',
    isPublic: true,
};

routes[routes.length] = {
    path: '/internal/account/confirm-email',
    componentName: 'ConfirmEmailPage',
    isPublic: true,
};

routes[routes.length] = {
    path: '/internal/account/confirm-email-change',
    componentName: 'ConfirmEmailChangePage',
    isPublic: true,
};

// Unsupported Browsers Page
routes[routes.length] = {
    path: '/unsupported-browser',
    componentName: 'UnsupportedBrowserRedirect',
    title: 'Unsupported Browser Redirection',
    isPublic: true,
};

routes[routes.length] = {
    path: '/internal/account/change-password',
    componentName: 'ChangePassword',
};

routes[routes.length] = {
    path: '/internal/account/my-profile',
    componentName: 'MyProfilePage',
};

routes[routes.length] = {
    path: '/internal/account/manage-attestation',
    componentName: 'AttestationManagement',
};

routes[routes.length] = {
    path: '/internal/account/manage-users',
    componentName: 'UserListBox',
};

routes[routes.length] = {
    path: '/internal/account/machine-api-permissions',
    componentName: 'MachineApiPermissionsPage',
};

routes[routes.length] = {
    path: '/internal/account/machine-api-keys',
    componentName: 'MachineApiKeysPage',
};

routes[routes.length] = {
    path: '/internal/account/device-tokens',
    componentName: 'RangerTokenManagementPage',
};

routes[routes.length] = {
    path: '/internal/account/create-user',
    componentName: 'UpdateUserPage',
};

routes[routes.length] = {
    path: '/internal/account/edit-user/:userId',
    componentName: 'UpdateUserPage',
};

routes[routes.length] = {
    path: '/internal/account/edit-locations',
    componentName: 'EditLocations',
};

routes[routes.length] = {
    path: '/internal/account/edit-roles',
    componentName: 'EditRoles',
};

routes[routes.length] = {
    path: '/internal/account/edit-concessionaires',
    componentName: 'EditConcessionaires',
};

routes[routes.length] = {
    path: '/internal/account/manage-concessionaires',
    componentName: 'ManageConcessionairesContainer',
};

routes[routes.length] = {
    path: '/internal/account/concessionaire-edit',
    componentName: 'EditConcessionaireContainer',
};

routes[routes.length] = {
    path: '/internal/account/concessionaire-details',
    componentName: 'ConcessionaireDetailsContainer',
};

routes[routes.length] = {
    path: '/internal/account/concessionaire-permit-edit',
    componentName: 'EditPermitContainer',
};

routes[routes.length] = {
    path: '/internal/account/manage-cooperators',
    componentName: 'ManageCooperatorsContainer',
};

routes[routes.length] = {
    path: '/internal/account/cooperator-details',
    componentName: 'CooperatorDetailsContainer',
};

routes[routes.length] = {
    path: '/internal/account/cooperator-edit',
    componentName: 'EditCooperatorContainer',
};

routes[routes.length] = {
    path: '/internal/account/cooperator-employee-edit',
    componentName: 'EditEmployeeContainer',
};

routes[routes.length] = {
    path: '/internal/account/facility-info/:locationType/:locationId',
    componentName: 'FacilityInfoPage',
};

routes[routes.length] = {
    path: '/internal/account/contact-us',
    componentName: 'ContactUs',
};

routes[routes.length] = {
    path: '/internal/account/training-links',
    componentName: 'TrainingLinks',
};

routes[routes.length] = {
    path: '/internal/account/oidc',
    componentName: 'OidcCallback',
    isPublic: true,
};

routes[routes.length] = {
    path: '/',
    componentName: 'LaunchPad',
};

export default routes;
